function isMobileNavOpen() {
    return document.body.classList.contains("mobile-nav-open");
}

function setMobileNavState(flag) {
    if (flag) {
        document.body.classList.add("mobile-nav-open");
    } else {
        document.body.classList.remove("mobile-nav-open");
    }
}

function getScrollTop() {
    // h/t https://raw.githubusercontent.com/yields/scrolltop/master/index.js
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}

$(() => {
    // Adds a toggle icon to mobile nav's each element that have child elements
    $(".page-nav-pages > ul li").each(function addMobileNavToggler() {
        if ($(this).find("ul").length) {
            $(this).children("a").after("<span class='mobile-toggle-icon'></span>");
        }
    });

    // Toggle navigation items when toggle icon is clicked
    $(".page-nav-pages ul li .mobile-toggle-icon").on("click", function toggleNavItems(e) {
        e.stopPropagation();
        $(this).parent().toggleClass("open");
    });

    /* Close mobile navigation if user clicks outside the nav element
    when the navigation is open */
    $(document).on("click", (e) => {
        if (isMobileNavOpen() && !$(e.target).closest(".page-nav-pages").length) {
            setMobileNavState(false);
        }
    });

    // Toggle the visibility of mobile navigation when the menu icon is clicked
    $("#mobile-nav-toggler").click((e) => {
        e.stopPropagation();
        setMobileNavState(!isMobileNavOpen());
    });

    // Define the scroll point where minify class is added to the navigation
    const navFixedTriggerPoint = parseInt($(".page-nav-bottom").offset().top);

    let running = false;

    function requestNavAppearance() {
        if (running) {
            return;
        }
        running = true;

        requestAnimationFrame(() => {
            if (getScrollTop() > navFixedTriggerPoint) {
                document.body.classList.add("nav-sticky");
            } else {
                document.body.classList.remove("nav-sticky");
            }
            running = false;
        });
    }

    ["scroll", "DOMContentLoaded"].forEach((type) => {
        window.addEventListener(type, requestNavAppearance);
    });
});
