const $heroElem = $('#hero-element');
const $heroContent = $('#hero-content');

function getHeroContentHeight() {
  let maxHeight = 0;
  // Get the tallest content inside the hero
  $heroElem.find($heroContent).each(function() {
      if ($(this).outerHeight() > maxHeight) {
          maxHeight = $(this).outerHeight();
      }
  });
  return maxHeight;
}

function setFullScreenHeight() {
  const heroMinHeight = getHeroContentHeight();
  const windowHeight = $(window).height();
  const navHeight = $('.page-nav-top').height() + $('.page-nav-bottom').height();
  // window height - top navigation height + 20px which is hidden under the navigation
  const calculatedHeight = windowHeight - navHeight + 20;

  if ($(window).width() > 991 && calculatedHeight > heroMinHeight) {
    // Set the hero to be the calculated height
    $heroElem.find('#hero-height-element').height(calculatedHeight);
  } else {
    // Set the hero height to be the same as the tallest content inside it
    $heroElem.find('#hero-height-element').height(heroMinHeight);
  }
}

// Debounce from underscore.js
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

$(() => {
  // Initialize hero height
  setFullScreenHeight();
});

$(window).resize(function() {
  // Re-set the height when updating screen size
  debounce(setFullScreenHeight(), 500);
});
