$(() => {
    // Enable Bootstrap tooltips
    $("[data-toggle='tooltip']").tooltip({
        delay: {
            show: 500,
            hide: 100,
        },
    });

    // Enable Bootstrap popovers
    $("[data-toggle='popover']").popover({
        placement: "auto",
    });

    // Generic script to scroll past the current section
    $(".scroll-down-button").on("click", function(e) {
        e.preventDefault();
        var scrollTarget = $(this).attr("data-target"); // Get the data-target value of the clicked btn
        var $scrollToElem = $("#" + scrollTarget); // Assign an element id with the same name than the target
        $('html, body').animate({
            scrollTop: $scrollToElem.offset().top - 70, // Scroll to the target element minus the navigation height
        }, 500);
    });
});
