$(() => {
    $(".carousel-some.owl-carousel").owlCarousel({
        responsive: {
            0: {
                items: 1,
            },
            500: {
                items: 2,
            },
            992: {
                items: 3,
            },
        },
    });
});
